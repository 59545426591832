import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

import { computed, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// Components - Import
import { BaseContainer, BaseColumn, BaseGrid, BaseSection } from '@/components/primitives';
import { BaseButton, Notification, LoadingSpinner, BaseSpacer } from '@/components/partials';
import { BaseForm } from '@/components/partials/forms';
import { ProductList } from '@/components/features';

// Constants - Import
import { StockStatus } from '@/constants/statuses/orderLineStatuses';

// Types - Import
import type { ComputedRef, Ref } from 'vue';
import type { ProductItem } from '@/types/product';
import type { WopsOrderInterface } from '@/classes/order';
import OrderProcessTypes from '@/constants/orderPriority/orderProcessTypes';

// @use - Import
import useOrder from '@/@use/order';
import useOrderLines from '@/@use/orderLines';
import outgoingOrder from '@/store/modules/outgoingOrder';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

/*------------------------------
- Vue
-- Import
- Components
-- Import
- Constants
-- Import
- Types
-- Import
--  Local
- @use
-- Import
-- Envoke
- Product click
- Complete pick
- Abandon pick
- errors
-------------------------------*/

// Vue - Import
onBeforeMount(() => {
  if (!productsByBrand.value.length) {
    store.dispatch('outgoingOrder/getAssignedItem', OrderProcessTypes.PICKING);
  }
});

// @use - Enoke
const router = useRouter();
const store = useStore();
const { productsByBrand, productCount } = useOrderLines();

// errors
const errors: Ref<Record<string, Array<string> | void>> = ref({});

// On product clicked
const onProductClicked = (product: ProductItem): void => {
  router.push({ name: 'PickingProduct', params: { product: product.id as string } });
};

// Complete pick
const processedProductsCount: ComputedRef<number> = computed(
  () =>
    productsByBrand.value?.filter(
      (product: ProductItem) =>
        product.status === StockStatus.PICKED || product.status === StockStatus.MISSING_STOCK
    ).length
);
const completedPicksDetail: ComputedRef<string> = computed(
  () => `(${processedProductsCount.value}/${productCount.value} processed)`
);
const canCompletePick: ComputedRef<boolean> = computed(
  () => !productsByBrand.value?.find((product: ProductItem) => product.status === undefined)
);
const onCompletePick = (): void => {
  router.push({
    name: 'PickingConfirm',
  });
};
const loading: ComputedRef<boolean> = computed(() => store.getters['outgoingOrder/getStoreLoading']);

// Abandon pick
const onAbandonPick = async (): Promise<void> => {
  await store.dispatch('outgoingOrder/unassignItem', OrderProcessTypes.PICKING).catch(({ message }) => {
    errors.value = {
      message: [message],
    };
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), { class: "v-picking-products" }, {
    default: _withCtx(() => [
      (loading.value)
        ? (_openBlock(), _createBlock(_unref(LoadingSpinner), { key: 0 }))
        : (_openBlock(), _createBlock(_unref(BaseGrid), {
            key: 1,
            class: "v-picking-products__grid"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseColumn), {
                span: { tablet: '6' },
                class: "v-picking-products__list-wrapper"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(ProductList), {
                    products: _unref(productsByBrand),
                    onListItemClicked: onProductClicked
                  }, null, 8, ["products"])
                ]),
                _: 1
              }),
              _createVNode(_unref(BaseColumn), {
                span: { tablet: '1' },
                class: "mobile-hidden"
              }),
              _createVNode(_unref(BaseColumn), { span: { tablet: '5' } }, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseButton), {
                    "button-disabled": !canCompletePick.value,
                    onClick: onCompletePick,
                    color: "success"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(" Complete pick " + _toDisplayString(completedPicksDetail.value), 1)
                    ]),
                    _: 1
                  }, 8, ["button-disabled"]),
                  _createVNode(_unref(BaseSpacer)),
                  _createVNode(_unref(BaseButton), { onClick: onAbandonPick }, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("Abandon pick")
                    ])),
                    _: 1
                  }),
                  _createVNode(_unref(BaseSpacer), { size: "4" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}
}

})