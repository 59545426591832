import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

// Components - Import
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseButton, BaseSpacer } from '@/components/partials';
import { VerticalTable } from '@/components/containers';
import { ProductCard } from '@/components/features';

// Constants - Import
import { StockStatus } from '@/constants/statuses/orderLineStatuses';
import { ActionColors } from '@/constants/colors';

// Types - Import
import type { ComputedRef } from 'vue';
import type { ProductItem } from '@/types/product';
import type { TableRow } from '@/types/table';

// @use - Import
import useOrderLines from '@/@use/orderLines';

// @use -Envoke

export default /*@__PURE__*/_defineComponent({
  __name: '_product',
  setup(__props) {

/*------------------------------
- Vue
-- Import
- Components
-- Import
- Constants
-- Import
- Types
-- Import
- @use
-- Import
-- Envoke
- Get product
- Update product status
- Table Data
-------------------------------*/

// Vue - Import
const route = useRoute();
const router = useRouter();
const store = useStore();
const { products } = useOrderLines();

onBeforeMount(() => {
  if (!products.value.length) {
    router.push({ name: 'PickingProducts' });
  }
});

// Get product
const product: ComputedRef<ProductItem | undefined> = computed(
  () => products.value.find((product: ProductItem) => product.id === route.params.product) || {}
);

// Update product status
const onProductMarkedAsPicked = async (): Promise<void> => {
  await onMarkProduct({
    color: ActionColors.SUCCESS,
    status: StockStatus.PICKED,
  });
};

const onProductMarkedAsOutOfStock = async (): Promise<void> => {
  await onMarkProduct({
    color: ActionColors.DANGER,
    status: StockStatus.MISSING_STOCK,
  });
};

const onMarkProduct = async (data: ProductItem): Promise<void> => {
  await store.dispatch('outgoingOrder/updateOutgoingProductDetails', {
    id: route.params.product,
    color: data.color,
    status: data.status,
  } as ProductItem);
  router.push({ name: 'PickingProducts' });
};

// Table data
const isTableDateLoaded: ComputedRef<boolean> = computed(() => !!product.value);
const tableRows: ComputedRef<TableRow[]> = computed(() => {
  return [
    {
      title: {
        content: 'SKU',
      },
      columns: [
        {
          content: product?.value?.sku || '',
        },
      ],
    },
    {
      title: {
        content: 'Bin',
      },
      columns: [{ content: 'N/A' }],
    },
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), { class: "v-picking-product__container" }, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseGrid), { class: "v-picking-product__grid" }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), { span: { tablet: '5' } }, {
            default: _withCtx(() => [
              (product.value)
                ? (_openBlock(), _createBlock(_unref(ProductCard), {
                    key: 0,
                    product: product.value
                  }, null, 8, ["product"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseColumn), {
            span: { tablet: '2' },
            class: "mobile-hidden"
          }),
          _createVNode(_unref(BaseColumn), {
            span: { tablet: '5' },
            justify: { tablet: 'between' }
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseGrid), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseColumn), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(VerticalTable), {
                        rows: tableRows.value,
                        "is-loaded": isTableDateLoaded.value
                      }, null, 8, ["rows", "is-loaded"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_unref(BaseGrid), null, {
                default: _withCtx(() => [
                  _createVNode(_unref(BaseColumn), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(BaseButton), {
                        onClick: onProductMarkedAsPicked,
                        color: "success"
                      }, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("Picked")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(BaseColumn), null, {
                    default: _withCtx(() => [
                      _createVNode(_unref(BaseButton), {
                        onClick: onProductMarkedAsOutOfStock,
                        color: "danger"
                      }, {
                        default: _withCtx(() => _cache[1] || (_cache[1] = [
                          _createTextVNode("No stock")
                        ])),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(BaseSpacer), { size: "4" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})