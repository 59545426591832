import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, ref, reactive, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// Components - Import
import { BaseContainer, BaseColumn, BaseGrid } from '@/components/primitives';
import { BaseText, BaseButton, BaseSpacer } from '@/components/partials';
import { VerticalTable } from '@/components/containers';
import { BaseForm, BaseInput, StandAloneLabel } from '@/components/partials/forms';

// Constants - Import
import { StockStatus } from '@/constants/statuses/orderLineStatuses';
import ShippingAddonNames from '@/constants/shippingAddons/shippingAddonNames';

// Helpers - Import
import { HelperFormErrors } from '@/helpers/form';

// Types - Import
import type { ComputedRef, Ref } from 'vue';
import type { WopsOrderInterface } from '@/classes/order';
import type { TableRow } from '@/types/table';
import type { HumanReadableBoolean } from '@/types/humanReadable';
import type { ProductItem } from '@/types/product';

// Types - Local
import useOrder from '@/@use/order';
import useOrderLines from '@/@use/orderLines';
import store from '@/store';
import OrderProcessTypes from '@/constants/orderPriority/orderProcessTypes';
import type { CompleteOutgoingProcessAction } from '@/store/types/outgoingOrder';

// @use - Envoke
interface PickingBinForm {
  binLocation: string;
  errors: HelperFormErrors;
}

// @use - Import

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

/*------------------------------
- Vue
-- Import
- Components
-- Import
- Constants
-- Import
- Types
-- Import
--  Local
- @use
-- Import
-- Envoke
- Form data
- Table data
-------------------------------*/

// Vue - Import
const router = useRouter();
const { deliveryMethod, isNewCustomer } = useOrder();
const { products, productCount } = useOrderLines();

onBeforeMount(() => {
  if (!products?.value?.length) {
    store.dispatch('outgoingOrder/getAssignedItem', OrderProcessTypes.PICKING);
  }
});

// Form data
const pickingBinForm: PickingBinForm = reactive({
  binLocation: '',
  errors: new HelperFormErrors(),
});

const isLoading: ComputedRef<boolean> = computed(() => store.getters['outgoingOrder/getStoreLoading']);
const onSubmitForPacking = async (): Promise<void> => {
  const data: CompleteOutgoingProcessAction = { type: OrderProcessTypes.PICKING };

  if (pickingBinForm.binLocation !== '') {
    data.binLocation = pickingBinForm.binLocation;
  }
  await store.dispatch('outgoingOrder/completeOutgoingProcess', data);
};

// Table data
const isTableDateLoaded: Ref<boolean> = ref(true);
const outOfStockProductsCount: ComputedRef<number> = computed(
  () => products.value?.filter((product: ProductItem) => product.status !== StockStatus.PICKED).length
);
const completedPicksDetail: ComputedRef<string> = computed(
  () => `(${outOfStockProductsCount?.value} not in stock)`
);

const tableRows: ComputedRef<TableRow[]> = computed(() => {
  return [
    {
      title: {
        content: 'Delivery',
      },
      columns: [
        {
          content: deliveryMethod.value,
          color: deliveryMethod.value === ShippingAddonNames.EXPRESS_DELIVERY ? 'danger' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'New customer',
      },
      columns: [
        {
          content: isNewCustomer.value,
          color: isNewCustomer.value === 'Yes' ? 'danger' : 'default',
        },
      ],
    },
    {
      title: {
        content: 'Products',
      },
      columns: [
        {
          content:
            outOfStockProductsCount.value > 0
              ? `${productCount.value} ${completedPicksDetail.value}`
              : productCount.value,
          color: outOfStockProductsCount.value > 0 ? 'danger' : 'default',
        },
      ],
    },
  ];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(VerticalTable), {
                rows: tableRows.value,
                "is-loaded": isTableDateLoaded.value
              }, null, 8, ["rows", "is-loaded"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseSpacer)),
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), { span: { default: '6' } }, {
            default: _withCtx(() => [
              _createVNode(_unref(StandAloneLabel), { name: "bin-location" }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Packing bin location")
                ])),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseColumn), { span: { default: '6' } }, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseInput), {
                label: "Bin No.",
                name: "bin-location",
                value: pickingBinForm.binLocation,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((pickingBinForm.binLocation) = $event)),
                "onReset:errors": _cache[1] || (_cache[1] = ($event: any) => (pickingBinForm.errors.clear('bin-location'))),
                error: pickingBinForm.errors.get('bin-location')
              }, null, 8, ["value", "error"])
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseSpacer))
        ]),
        _: 1
      }),
      _createVNode(_unref(BaseGrid), null, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), {
                "is-loading": isLoading.value,
                onClick: onSubmitForPacking,
                color: "success"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" Submit for Packing ")
                ])),
                _: 1
              }, 8, ["is-loading"])
            ]),
            _: 1
          }),
          _createVNode(_unref(BaseColumn), null, {
            default: _withCtx(() => [
              _createVNode(_unref(BaseButton), { url: "/picking/products" }, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("Back to Pick List")
                ])),
                _: 1
              }),
              _createVNode(_unref(BaseSpacer), { size: "4" })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})